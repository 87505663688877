import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useTranslation } from '@contexts/translation'
import { Grid, Button, MenuItem, TextField, Chip } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import TextFieldItem from '@components/common/Form/TextFieldItem'

import { onUpdateOrgUser, postOrguserInvite } from '@api/orgusers'
import { responseHandler } from '@utils/responseHandler'
import API_URL from '@config/services'

const UserForm = ({
  handleClose,
  orgusersURL,
  /* isGlobal, */
  isEdit = false,
  userData = null,
  tenantId = null
}) => {
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [fetchUsersURL, setFetchUsersURL] = useState(null)

  const schema = yup.object().shape({
    first_name: yup.string().required(dictionary.required),
    last_name: yup.string().required(dictionary.required),
    email: yup.string().email().required(dictionary.required),
    type: yup.string()
    /*  user: isEdit
      ? yup.string()
      : yup.string().required(dictionary.required)  */
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      type: '',
      user: ''
    }
  })

  const roleList = isEdit
    ? ['manager', 'accountant']
    : ['manager', 'tenant', 'landlord', 'supplier', 'accountant']

  if (isEdit && !roleList.find(role => role === userData.role)) {
    roleList.push(userData.role)
  }

  const userType = watch('type')

  useEffect(() => {
    switch (userType) {
      case 'tenant':
        !isEdit && setValue('user', '')
        setFetchUsersURL(API_URL.TENANTS.GET_TENANTS_LIST())
        break
      case 'landlord':
        !isEdit && setValue('user', '')
        setFetchUsersURL(API_URL.LANDLORDS.GET_LANDLORDS_LIST())
        break
      case 'supplier':
        !isEdit && setValue('user', '')
        setFetchUsersURL(API_URL.SUPPLIERS.GET_SUPPLIERS_LIST())
        break
      case 'accountant':
        !isEdit && setValue('user', '')
        setFetchUsersURL(null)
        break
      case 'manager':
        !isEdit && setValue('user', '')
        setFetchUsersURL(null)
        break

      default:
        !isEdit && setValue('user', '')
        setFetchUsersURL(null)
        break
    }
  }, [userType, setValue])

  useEffect(() => {
    if (userData) {
      setValue('first_name', userData.first_name || '')
      setValue('last_name', userData.last_name || '')
      setValue('email', userData.email || '')
      setValue('type', userData.role || '')
      if (userData.role !== 'manager' && userData.role !== 'accountant') {
        setValue('user', userData[userData.role]?.id)
      }
    }
  }, [userData])

  // const { data: users, isLoading } = useFetchData(fetchUsersURL)

  const onSubmit = async values => {
    setLoading(true)
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      ...(!isEdit ? { email: values.email, [values.type]: values.user } : {})
    }
    if (values.type === 'manager' || values.type === 'accountant') {
      payload.type = values.type
    }
    if (tenantId) {
      payload.type = 'tenant'
      payload.tenant = tenantId
    }

    const response = isEdit
      ? await onUpdateOrgUser(userData.id, payload)
      : await postOrguserInvite(payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(orgusersURL)
        handleClose()
      },
      dictionary,
      msgSuccess: `${
        !isEdit ? dictionary.sending_invitation_to : dictionary.orguser_updated
      } ${values.email}`,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    !isEdit && handleClose && handleClose()
  }

  const translateRole = role => {
    switch (role) {
      case 'manager':
        return dictionary.manager
      case 'owner':
        return dictionary.owner
      case 'tenant':
        return dictionary.tenant
      case 'landlord':
        return dictionary.landlord
      case 'supplier':
        return dictionary.supplier
      case 'accountant':
        return dictionary.accountant
      case 'investor':
        return dictionary.investor
      default:
        return null
    }
  }

  return (
    <form
      data-cy={'form'}
      style={{ width: '100%' }}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      data-testid="UserForm"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="first_name"
            control={control}
            render={props => (
              <TextFieldItem
                required
                type="first_name"
                placeholder={dictionary.first_name}
                label={dictionary.first_name}
                error={Boolean(errors.first_name)}
                errorMessage={errors.first_name?.message}
                {...props}
                data_testid="TextFieldItem-4e9725e4-0556-4f71-8103-2d028d78dbea"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="last_name"
            control={control}
            render={props => (
              <TextFieldItem
                required
                type="last_name"
                placeholder={dictionary.last_name}
                label={dictionary.last_name}
                error={Boolean(errors.last_name)}
                errorMessage={errors.last_name?.message}
                {...props}
                data_testid="TextFieldItem-ce074516-dd65-4e97-a7cf-fe6b00daabe3"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={props => (
              <TextFieldItem
                required
                type="email"
                placeholder={dictionary.email}
                label={dictionary.email}
                error={Boolean(errors.email)}
                errorMessage={errors.email?.message}
                {...props}
                disabled={isEdit}
                data_testid="TextFieldItem-aba5cb6d-2166-4ecb-ba9a-fff4cb6e6cf9"
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Controller
            control={control}
            name="type"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                select
                fullWidth
                variant="filled"
                label={dictionary.type}
                control={control}
                error={Boolean(errors.type)}
                helperText={errors.type?.message}
                disabled={isEdit}
              >
                {roleList.map(category => (
                  <MenuItem
                    key={category}
                    value={category}
                    disabled={category === 'supplier'}
                    data-testid="MenuItem-43e5883c-d537-49fb-be2e-f6bfacc4f194"
                  >
                    {upperFirst(translateRole(category))}{' '}
                    {category === 'supplier' && (
                      <Chip
                        sx={{ ml: 2 }}
                        label={dictionary.coming_soon}
                        data-testid="Chip-9b082ac0-b13c-4ab0-997a-21d2201a72fe"
                      />
                    )}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid> */}

        {/* {userType && userType !== 'manager' && userType !== 'owner' && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name={'user'}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  required
                  fullWidth
                  variant="filled"
                  label={dictionary[userType]}
                  control={control}
                  error={Boolean(errors.user)}
                  helperText={errors.user?.message}
                  disabled={isEdit}
                >
                  {isLoading ? (
                    <MenuItem data-testid="MenuItem-7beb5369-2351-4e04-98c6-f3707fc5079f" />
                  ) : (
                    users &&
                    users.results.map(user => (
                      <MenuItem
                        key={user.id}
                        value={user.id}
                        data-testid="MenuItem-d6771ff8-3b70-4606-96f9-8e0cbcb871f0"
                      >
                        {user.display}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              )}
            />
          </Grid>
        )} */}

        <Grid item xs={12} align="right">
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            data-testid="Button-ac43738a-c2ca-4736-b28b-938753455804"
          >
            {isEdit ? dictionary.submit : dictionary.send_invitation}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default UserForm
