import React, { useState } from 'react'

import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import { useTheme } from '@mui/material/styles'

import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  TableFooter,
  Typography,
  Chip,
  TableSortLabel
} from '@mui/material'

import PlaceHolder from '@components/common/PlaceHolder'
import numberWithCommas from '@utils/numberWithCommas'

import ActionsPopover from '@components/common/ActionsPopover'
import { setItemsSort } from '@utils/sortTables'
import getTotal from '@utils/getTotal'
import FixedFooter from '@components/FixedFooter'
import CommonPagination from './Pagination'
import { useAuth } from '@contexts/auth'
import { useRouter } from 'next/router'

export default function LeasesTable(props) {
  const {
    setDetailModal,
    isInDetailModal = false,
    tenantOperations = [],
    membershipIsReadOnly = false,
    isArticleLotTable = false,
    isLoading,
    currency,
    leases = [],
    setLeases,
    queries,
    setQueries,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    ACTIONS = () => []
  } = props
  const { user } = useAuth()
  const { dictionary, t } = useTranslation()
  const theme = useTheme()
  const classesTable = useTableStyles()
  const router = useRouter()
  const [sort, setSort] = useState('asc')
  const [sortBy, setSortBy] = useState()

  const currentURL = router.asPath

  const isReadOnly = isInDetailModal || membershipIsReadOnly

  const leaseChipStatusColors = status => {
    switch (status) {
      case 'draft':
        return {
          label: dictionary.draft,
          color: 'highlight.red',
          backgroundColor: 'highlight.lightRed'
        }
      case 'prospect':
        return {
          label: dictionary.prospect,
          color: 'highlight.blue',
          backgroundColor: 'highlight.lightBlue'
        }
      case 'not_started':
        return {
          label: dictionary.not_started,
          color: 'primary.main',
          backgroundColor: 'highlight.lightPurple'
        }
      case 'active':
        return {
          label: dictionary.active_m,
          color: 'highlight.green',
          backgroundColor: 'highlight.lightGreen'
        }
      case 'expired':
        return {
          label: dictionary.expired,
          color: 'dark.main',
          backgroundColor: 'highlight.lightBlack'
        }
      case 'canceled':
        return {
          label: dictionary.canceled,
          color: 'highlight.red',
          backgroundColor: 'highlight.lightRed'
        }
      case 'archived':
        return {
          label: dictionary.archived,
          color: 'highlight.gold',
          backgroundColor: 'highlight.lightGold'
        }
      default:
        return ''
    }
  }

  const isTenantPage =
    user.role === 'tenant' || currentURL.includes('tenant/preview')

  const isLotsPage = window.location.href.includes('lots/lots')

  let totalCol = isTenantPage || isLotsPage ? 6 : 7

  const FooterObjData = {
    0: { name: 'Total', align: 'left' },
    4: {
      name: numberWithCommas(
        getTotal(leases, 'rent_data.rent_total'),
        2,
        currency
      )
    },
    5: {
      name: numberWithCommas(getTotal(leases, 'balance'), 2, currency)
    }
  }

  return (
    <Grid item xs={12}>
      <TableContainer className={classesTable.container}>
        <Table>
          {leases?.length > 0 && (
            <TableHead>
              <TableRow hover={false}>
                {!tenantOperations.length > 0 ? (
                  <TableCell className={classesTable.headerCell}>
                    <TableSortLabel
                      active={sortBy == 'display'}
                      direction={sort}
                      onClick={() =>
                        setItemsSort(
                          'display',
                          setSortBy,
                          sort,
                          setSort,
                          setLeases
                        )
                      }
                    >
                      {dictionary.tenant}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell className={classesTable.headerCell}>
                    <TableSortLabel
                      active={sortBy == 'operation'}
                      direction={sort}
                      onClick={() =>
                        setItemsSort(
                          'operation',
                          setSortBy,
                          sort,
                          setSort,
                          setLeases
                        )
                      }
                    >
                      {dictionary.project}
                    </TableSortLabel>
                  </TableCell>
                )}

                <TableCell className={classesTable.headerCell}>
                  <TableSortLabel
                    active={sortBy == 'start_date'}
                    direction={sort}
                    onClick={() =>
                      setItemsSort(
                        'start_date',
                        setSortBy,
                        sort,
                        setSort,
                        setLeases
                      )
                    }
                  >
                    {dictionary.start_date}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classesTable.headerCell}>
                  <TableSortLabel
                    active={sortBy == 'end_date'}
                    direction={sort}
                    onClick={() =>
                      setItemsSort(
                        'end_date',
                        setSortBy,
                        sort,
                        setSort,
                        setLeases
                      )
                    }
                  >
                    {dictionary.end_date}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classesTable.headerCell} align="center">
                  <TableSortLabel
                    active={sortBy == 'status'}
                    direction={sort}
                    onClick={() =>
                      setItemsSort(
                        'status',
                        setSortBy,
                        sort,
                        setSort,
                        setLeases
                      )
                    }
                  >
                    {dictionary.status}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  <TableSortLabel
                    active={sortBy == 'rent'}
                    direction={sort}
                    onClick={() =>
                      setItemsSort('rent', setSortBy, sort, setSort, setLeases)
                    }
                  >
                    {(isArticleLotTable ? dictionary.lot + ' ' : '') +
                      dictionary.rent}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classesTable.headerCell} align="right">
                  <TableSortLabel
                    active={sortBy == 'balance'}
                    direction={sort}
                    onClick={() =>
                      setItemsSort(
                        'balance',
                        setSortBy,
                        sort,
                        setSort,
                        setLeases
                      )
                    }
                  >
                    {dictionary.balance}
                  </TableSortLabel>
                </TableCell>
                {!isReadOnly && <TableCell />}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isLoading && (
              <TableRow className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <CircularProgress color="secondary" />
                </TableCell>
              </TableRow>
            )}

            {!isLoading && !leases.length > 0 && (
              <TableRow hover={false} className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <PlaceHolder
                    src="/Placeholders/Keysy/Baux.png"
                    wordingBordered={t('projects_leases_lease_no_rental')}
                  />
                </TableCell>
              </TableRow>
            )}

            {!isLoading &&
              leases.length > 0 &&
              leases.map((lease, idx) => (
                <TableRow
                  key={idx}
                  className={classesTable.row}
                  sx={{ cursor: isTenantPage ? null : 'pointer' }}
                  onClick={
                    isTenantPage
                      ? null
                      : () => setDetailModal({ open: true, id: lease.id })
                  }
                >
                  {!tenantOperations.length > 0 ? (
                    <TableCell>
                      {lease.display}
                      <Typography
                        sx={{ color: theme.palette.text.empty, fontSize: 10 }}
                        data-testid="Typography-1b5690ab-b427-4cc1-a897-69a579c78950"
                      >
                        {lease.unique_identifier}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell>
                      {
                        tenantOperations.find(
                          operation => operation.id === lease.operation
                        ).display
                      }
                    </TableCell>
                  )}

                  <TableCell>{lease.start_date}</TableCell>
                  <TableCell>{lease.end_date}</TableCell>
                  <TableCell align="center">
                    <Chip
                      size="small"
                      label={leaseChipStatusColors(lease?.status).label}
                      sx={{
                        height: 26,
                        fontSize: 12,
                        fontWeight: 500,
                        color: leaseChipStatusColors(lease?.status).color,
                        backgroundColor: leaseChipStatusColors(lease?.status)
                          .backgroundColor,
                        borderRadius: '8px'
                      }}
                      data-testid="Chip-ca22f352-a97e-4559-86cd-6b85068317ba"
                    />
                  </TableCell>
                  <TableCell align="right">
                    {numberWithCommas(
                      lease?.rent_data?.rent_total,
                      2,
                      currency
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {numberWithCommas(
                      isArticleLotTable ? lease.provision : lease.balance,
                      2,
                      currency
                    )}
                  </TableCell>
                  {!isReadOnly && (
                    <TableCell className={classesTable.rowActions}>
                      <ActionsPopover actions={ACTIONS(lease.id, lease)} />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
          {!isLoading && leases?.length > 0 && (
            <TableFooter className={classesTable.footer}>
              <FixedFooter data={FooterObjData} totalCol={totalCol} />
              <CommonPagination
                queries={queries}
                setQueries={setQueries}
                count={count}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Grid>
  )
}
