import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import CommonModal from '@components/common/Modal/CommonModal'
import LeaseDetails from '@components/Leases/LeaseDetails'
import LeasesTable from '@components/common/LeasesTable'
import queryString from '@utils/queryString'

const LeasesInfo = ({ tenantId, operationId, currency }) => {
  const { dictionary } = useTranslation()
  const [count, setCount] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [detailModal, setDetailModal] = useState({
    open: false,
    id: null
  })
  const initialQueries = {
    page: 1,
    page_size: rowsPerPage
  }
  const [queries, setQueries] = useState(initialQueries)

  const leaseURL = tenantId
    ? API_URL.TENANTS.GET_TENANT_LEASES(tenantId) + '&' + queryString(queries)
    : null
  const { data, isLoading } = useFetchData(leaseURL)

  useEffect(() => {
    if (data) {
      setCount(data.count)
    }
  }, [data])

  const tenantURL = tenantId ? API_URL.TENANTS.GET_TENANT_BY_ID(tenantId) : null

  const { data: tenantData } = useFetchData(tenantURL)

  return (
    <>
      <LeasesTable
        isInDetailModal
        tenantOperations={tenantData?.operations}
        currency={currency}
        leases={data?.results}
        isLoading={isLoading}
        setDetailModal={setDetailModal}
        queries={queries}
        setQueries={setQueries}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
      <CommonModal
        open={detailModal.open}
        title={`${dictionary.lease} ${dictionary.details}`}
        onClose={() => setDetailModal({ open: false, id: null })}
        maxWidth={'lg'}
        noPadding
        isAlignTop
      >
        <LeaseDetails
          handleClose={() => setDetailModal({ open: false, id: null })}
          operationId={operationId}
          leaseId={detailModal.id}
          fetchURL={leaseURL}
          currency={currency}
        />
      </CommonModal>
    </>
  )
}

export default LeasesInfo
