import client from '@config/client'
import API_URL from '@config/services'

/**
 * Orgusers
 **/

export const fetchOrguser = orgPk => {
  return client
    .get(API_URL.ORG_USER.GET_ORG_USER_PK(orgPk))
    .then(response => response.data.results)
    .catch(error => error.response)
}

export const getOrguser = () => {
  return client
    .get(API_URL.ORG_USER.GET_ORG_USERS)
    .then(response => response)
    .catch(error => error.response)
}

export const postOrganization = payload => {
  return client
    .post(API_URL.ORG_USER.POST_ORGANIZATION, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const onUpdateOrgUser = (id, payload) => {
  return client
    .patch(API_URL.ORG_USER.UPDATE_ORG_USER(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const postOrguserInvite = payload => {
  return client
    .post(API_URL.ORG_USER.POST_ORG_USER_INVITE, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteOrguser = id => {
  return client
    .delete(API_URL.ORG_USER.DELETE_ORG_USER(id))
    .then(response => response)
    .catch(error => error.response)
}
