import { useState, useEffect } from 'react'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { useTranslation } from '@contexts/translation'
import { onDeleteTask, onLogTasks } from '@api/tasks'

import TaskForm from '@components/Tasks/TaskForm'
import AddTasksLabelsForm from '@components/Tasks/AddTasksLabelsForm'
import AddTasksListsForm from '@components/Tasks/AddTasksListsForm'
import TableView from '@components/Tasks/TasksTable/TableView'

import CommonModal from '@components/common/Modal/CommonModal'
import FilterActionsSelector from '@components/common/Filter/ActionsSelector'
import { Button, Stack } from '@mui/material'
import {
  Add,
  FileDownload,
  Inventory2,
  ListAlt,
  PictureAsPdf
} from '@mui/icons-material'
import { useAuth } from '@contexts/auth'
import { useFetchData } from '@api/fetchData'

import DeleteModal from '@components/common/DeleteModal'
import downloadPDF from '@utils/downloadPDF'
import downloadXLS from '@utils/downloadXLS'
import { responseHandler } from '@utils/responseHandler'

const TasksTable = ({
  queries,
  setQueries,
  ordering,
  taskLists,
  orgusers,
  fetchListsURL,
  fetchTasksURL,
  fetchLabelsURL,
  progressURL,
  projectId = null,
  phaseId = null,
  isPagination = false,
  rowsPerPage = 10,
  setRowsPerPage = () => {},
  page = 0,
  setPage = () => {},
  isDetailModal = false,
  lotId = null,
  fetchDetailModalURL = null
}) => {
  const { organization, teamSettings } = useAuth()

  const { enqueueSnackbar } = useSnackbar()
  const { dictionary } = useTranslation()

  const [items, setItems] = useState([])
  const [taskId, setTaskId] = useState(null)

  const [isDelete, setIsDelete] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isLogConfirm, setIsLogConfirm] = useState(false)
  const [loading, setLoading] = useState(false)

  const [isModalFormOpen, setIsModalFormOpen] = useState(false)
  const [isLabelsFormOpen, setIsLabelsFormOpen] = useState(false)
  const [isListsFormOpen, setIsListsFormOpen] = useState(false)

  const { data, isLoading: isLoadingData } = useFetchData(fetchTasksURL)
  const tasks = data?.results
  const count = data?.count

  useEffect(() => {
    if (ordering === '0') setItems(tasks.sort((a, b) => a.order - b.order))
    else setItems(tasks)
  }, [tasks, ordering])

  const deleteTask = async () => {
    setLoading(true)
    const response = await onDeleteTask(taskId)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchTasksURL)
        await mutate(fetchListsURL)
        progressURL && (await mutate(progressURL))
      },
      dictionary,
      msgSuccess: dictionary.task_deleted,
      snackbar: enqueueSnackbar
    })

    setIsDelete(false)
    setLoading(false)
    setTaskId(null)
    setIsEdit(false)
  }

  const logTasks = async () => {
    setLoading(true)
    const response = await onLogTasks(organization)

    responseHandler({
      response,
      callback: async () => await mutate(fetchTasksURL),
      dictionary,
      msgSuccess: dictionary.tasks_logged,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    setIsLogConfirm(false)
  }

  const handleModalClose = () => {
    setIsModalFormOpen(false)
    setIsEdit(false)
    setIsLabelsFormOpen(false)
    setIsListsFormOpen(false)
    setTaskId(null)
  }

  const downloadData = items?.map(d => {
    return {
      [dictionary.deadline]: d.due_date,
      [dictionary.title]: d.title,
      [dictionary.project]: d?.operation?.display,
      [dictionary.list]: d.list_task?.display,
      [dictionary.assigned_to]: d.assigned_to?.display
    }
  })

  return (
    <>
      {/* {navbar && ( */}
      {!isDetailModal && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ marginBottom: 3, paddingTop: 2 }}
        >
          <Stack direction="row" spacing={3}>
            <Button
              startIcon={<Add />}
              data-cy={'add-btn'}
              onClick={() => setIsModalFormOpen(true)}
              data-testid="Button-f6494adf-0e12-48ba-bdad-eddd73507bb0"
            >
              {dictionary.new_task}
            </Button>
            {!queries.is_logged && (
              <Button
                startIcon={<Inventory2 />}
                color="warning"
                disabled={
                  items &&
                  !items.some(
                    i => i.is_completed === true && i.is_logged === false
                  )
                }
                onClick={() => setIsLogConfirm(true)}
                data-testid="Button-3eb3c230-c168-4caa-8af3-bbb6d74d22fb"
              >
                {dictionary.Archive_completed_tasks}
              </Button>
            )}
          </Stack>

          <Stack direction="row" spacing={3}>
            <FilterActionsSelector
              isMenu
              options={[
                {
                  label: dictionary.actions,
                  handleClick: () => null
                },
                {
                  /*{
            icon: <Bookmarks color="secondary" />,
            label: dictionary.manage_labels,
            handleClick: () => setIsLabelsFormOpen(true)
            }*/
                },
                {
                  icon: <ListAlt color="secondary" />,
                  label: dictionary.manage_lists,
                  handleClick: () => setIsListsFormOpen(true)
                },
                {
                  icon: <FileDownload color="secondary" />,
                  label: dictionary.export_xls,
                  disabled: !downloadData?.length,
                  handleClick: () => downloadXLS(downloadData, dictionary.tasks)
                },
                {
                  icon: <PictureAsPdf color="secondary" />,
                  label: dictionary.export_pdf,
                  disabled: !downloadData?.length,
                  handleClick: () =>
                    downloadPDF(
                      downloadData,
                      dictionary.tasks,
                      teamSettings?.name
                    )
                }
              ]}
            />
          </Stack>
        </Stack>
      )}

      <TableView
        isPagination={isPagination}
        items={items}
        setItems={setItems}
        isLoadingData={isLoadingData}
        orgusers={orgusers}
        //taskLabels={taskLabels}
        fetchTasksURL={fetchTasksURL}
        setIsEdit={setIsEdit}
        setIsDelete={setIsDelete}
        setTaskId={setTaskId}
        queries={queries}
        setQueries={setQueries}
        count={count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        projectId={projectId}
        isDetailModal={isDetailModal}
        lotId={lotId}
      />

      <CommonModal
        title={`${isEdit ? dictionary.edit : dictionary.add} ${
          dictionary.task
        }`}
        open={isModalFormOpen || isEdit}
        onClose={handleModalClose}
        maxWidth={fetchDetailModalURL ? 'sm' : 'md'}
      >
        {(isModalFormOpen || isEdit) && (
          <TaskForm
            handleClose={handleModalClose}
            isEdit={isEdit}
            taskId={taskId}
            mutateURLArray={[fetchTasksURL, progressURL]}
            lists={taskLists}
            phaseId={phaseId}
            fetchDetailModalURL={fetchDetailModalURL}
          />
        )}
      </CommonModal>
      <CommonModal
        title={dictionary.task_labels}
        open={isLabelsFormOpen}
        onClose={handleModalClose}
        maxWidth={'md'}
      >
        {isLabelsFormOpen && (
          <AddTasksLabelsForm
            fetchLabelsURL={fetchLabelsURL}
            fetchTasksURL={fetchTasksURL}
          />
        )}
      </CommonModal>
      <CommonModal
        title={dictionary.task_lists}
        open={isListsFormOpen}
        onClose={handleModalClose}
        maxWidth={'md'}
      >
        {isListsFormOpen && (
          <AddTasksListsForm
            taskLists={taskLists?.results}
            orgusers={orgusers}
            fetchListsURL={fetchListsURL}
            fetchTasksURL={fetchTasksURL}
          />
        )}
      </CommonModal>
      <DeleteModal
        open={isDelete}
        onClose={() => setIsDelete(false)}
        onConfirm={deleteTask}
        description={dictionary.confirm_to_delete}
        loading={loading}
      />

      <DeleteModal
        open={isLogConfirm}
        onClose={() => setIsLogConfirm(false)}
        onConfirm={logTasks}
        description={`${dictionary.Archive_completed_tasks} ? (${
          items?.filter(i => i.is_completed).length
        } ${dictionary.tasks})`}
        loading={loading}
      />
    </>
  )
}

export default TasksTable
