import { useEffect, useState } from 'react'
import { mutate } from 'swr'
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  Grid,
  TableBody,
  Button,
  Box,
  IconButton
} from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import { useAuth } from '@contexts/auth'
import { useTranslation } from '@contexts/translation'
import { onCreateLabel, onDeleteLabel, onEditLabel } from '@api/labels'

import CommonPopoverForm from '@components/common/PopoverForm/CommonPopoverForm'

import useTableStyles from '@styles/table/table'
import {
  DraggableComponent,
  DroppableComponent,
  onDragEnd
} from '@utils/dragAndDrop'
import DeleteModal from '@components/common/DeleteModal'
import PlaceHolder from '@components/common/PlaceHolder'
import { responseHandler } from '@utils/responseHandler'

// const colors = [
//   '#ed5565',
//   '#337ab7',
//   '#004068',
//   '#009aae',
//   '#23c6c8',
//   '#fe9b00',
//   '#888888'
// ]

export default function AddTasksLabelsForm({
  taskLabels,
  fetchLabelsURL,
  fetchTasksURL
}) {
  const classesTable = useTableStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { dictionary } = useTranslation()
  const { organization } = useAuth()

  const [items, setItems] = useState([])
  const [label, setLabel] = useState(null)
  const [isDelete, setIsDelete] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setItems(taskLabels.sort((a, b) => (a.order > b.order ? 1 : -1)))
  }, [taskLabels])

  const handleCreateLabel = async payload => {
    const response = await onCreateLabel(organization, payload)

    responseHandler({
      response,
      callback: async () => await mutate(fetchLabelsURL),
      dictionary,
      msgSuccess: dictionary.label_created,
      snackbar: enqueueSnackbar
    })
  }

  const handleUpdateLabel = async (id, payload) => {
    const response = await onEditLabel(id, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchLabelsURL)
        await mutate(fetchTasksURL)
      },
      dictionary,
      msgSuccess: dictionary.label_updated,
      snackbar: enqueueSnackbar
    })
  }

  const deleteLabel = async () => {
    setLoading(true)
    const response = await onDeleteLabel(label.pk)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchLabelsURL)
        await mutate(fetchTasksURL)
      },
      dictionary,
      msgSuccess: dictionary.label_deleted,
      snackbar: enqueueSnackbar
    })

    setIsDelete(false)
    setLoading(false)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CommonPopoverForm
          style={{ marginLeft: 0 }}
          buttonLabel={
            <Button
              startIcon={<Add />}
              data-testid="Button-485dbac5-cb90-4b5c-9588-b780a1e3cfbf"
            >
              {dictionary.add_new_label}
            </Button>
          }
          propertyLabel={dictionary.title}
          defaultValue={''}
          onSubmit={values =>
            handleCreateLabel({
              title: values.textPopover
            })
          }
          data_testid="CommonPopoverForm-1731f4fc-b505-46e2-a285-0535553e7f82"
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer className={classesTable.container}>
          <Table aria-label="labels-list-dense-table">
            <TableHead>
              <TableRow hover={false}>
                <TableCell />
                <TableCell className={classesTable.headerCell}>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    data-testid="Typography-c9276bd3-55eb-4905-a2e7-ec437c91adbd"
                  >
                    {dictionary.title}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classesTable.headerCell}>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    data-testid="Typography-063f8dc1-ef9a-4d7d-a4da-f0ed9895d0ba"
                  >
                    {dictionary.color}
                  </Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody
              component={DroppableComponent(res =>
                onDragEnd(res, items, setItems, onEditLabel)
              )}
            >
              {!items.length && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <PlaceHolder
                      src="/Placeholders/Project_list.png"
                      width={350}
                      height={350}
                    />
                  </TableCell>
                </TableRow>
              )}

              {items &&
                items.map((l, index) => (
                  <TableRow
                    key={l.pk}
                    className={classesTable.row}
                    component={DraggableComponent(
                      l.pk.toString(),
                      index,
                      classesTable.row
                    )}
                  >
                    <TableCell>
                      <CommonPopoverForm
                        style={{ marginLeft: 0 }}
                        variant="changeOptionDark"
                        buttonLabel={l.title}
                        propertyLabel={dictionary.title}
                        defaultValue={l.title || ''}
                        onSubmit={values =>
                          handleUpdateLabel(l.pk, {
                            title: values.textPopover
                          })
                        }
                        data_testid="CommonPopoverForm-8ea1310f-427e-48d7-b85c-d7aa83c14876"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <CommonPopoverForm
                        type="colorPicker"
                        style={{ margin: 'auto' }}
                        buttonLabel={
                          <Box
                            sx={{
                              margin: 'auto',
                              width: 20,
                              height: 20,
                              backgroundColor: l.color,
                              cursor: 'pointer'
                            }}
                          />
                        }
                        propertyLabel={dictionary.color}
                        defaultValue={l.color || '#aabbcc'}
                        onSubmit={values =>
                          handleUpdateLabel(l.pk, {
                            color: values.colorPickerPopover
                          })
                        }
                        data_testid="CommonPopoverForm-7e6ac8c9-2ffa-4cad-adaf-1e7a640202bf"
                      />
                    </TableCell>
                    <TableCell className={classesTable.rowActions}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setLabel(l)
                          setIsDelete(true)
                        }}
                        data-testid="IconButton-42e07f18-d448-40da-aee9-28d10a62d31d"
                      >
                        <Delete fontSize="small" color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <DeleteModal
          open={isDelete}
          onClose={() => setIsDelete(false)}
          onConfirm={deleteLabel}
          description={dictionary.confirm_to_delete}
          loading={loading}
        />
      </Grid>
    </Grid>
  )
}
