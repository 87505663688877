import { TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import React from 'react'
import useTableStyles from '@styles/table/table'

const FixedFooter = ({
  totalCol,
  data,
  classFooter,
  secondElementCell = false
}) => {
  const classesTable = useTableStyles()
  const EArray = Array.from({ length: totalCol }, (v, i) => i)

  return (
    <TableRow
      className={
        classFooter ? classesTable.rowTotalSecondary : classesTable.rowTotal
      }
      hover={false}
    >
      {EArray.map((item, index) => {
        return data?.[index] == undefined ? (
          <TableCell key={index} />
        ) : data[index].tooltip ? (
          <TableCell
            key={index}
            align={data[index].align ? data[index].align : 'right'}
            sx={{ whiteSpace: 'nowrap' }}
          >
            <Tooltip title={data[index].tooltip}>
              <Typography
                variant="subtitle2"
                color={
                  classFooter
                    ? 'primary'
                    : data[index].text
                    ? null
                    : data[index].color
                    ? data[index].color
                    : 'secondary'
                }
                fontWeight={'bold'}
                data-testid="Typography-c0e33b46-f7ce-43f5-8ddd-5cde718cd422"
              >
                {data[index].name}
              </Typography>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell
            key={index}
            align={data[index].align ? data[index].align : 'right'}
            sx={{ whiteSpace: 'nowrap' }}
          >
            <Typography
              variant="subtitle2"
              color={
                classFooter
                  ? 'primary'
                  : data[index].text
                  ? null
                  : data[index].color
                  ? data[index].color
                  : 'secondary'
              }
              fontWeight={'bold'}
              data-testid="Typography-6b895232-45c5-4d12-89ba-b550c6d7d6b8"
            >
              {data[index].name}
            </Typography>
            {index > 0 && secondElementCell && (
              <>
                <Typography
                  variant="body2"
                  color="secondary"
                  ml={2}
                  fontWeight={'bold'}
                  data-testid="Typography-14e99d00-a745-4bc9-b70c-4b4165e7d1c8"
                >
                  {data[index].secondElement}
                </Typography>
                <Typography
                  variant="body2"
                  color={
                    data[index].colorDiff ? data[index].colorDiff : 'secondary'
                  }
                  fontWeight={'bold'}
                  data-testid="Typography-e62baa11-ec32-4c97-815b-a401919f0079"
                >
                  {data[index].diff ? `Diff: ${data[index].diff}` : ''}
                </Typography>
              </>
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default FixedFooter
