import React, { useCallback, useMemo, useState } from 'react'

import { Button, FormControlLabel, Grid, Switch } from '@mui/material'
import routes from '@config/routes'
import { useRouter } from 'next/router'
import TableTemplate from '@components/common/Table'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import CommonModal from '@components/common/Modal/CommonModal'
import UserForm from '@components/Account/Users/Form/UserForm'
import { useAuth } from '@contexts/auth'
import formatURL from '@utils/formatURL'

export default function Portal(props) {
  const { tenantId, enabled = false } = props
  const router = useRouter()
  const { t } = useTranslation()

  const { organization } = useAuth()

  const invitationsListURL =
    API_URL.INVITATIONS.GET_INVITATIONS_LIST(organization)

  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [activateChecked, setActivateChecked] = useState(false)

  const handleActivateChange = event => {
    setActivateChecked(event.target.checked)
  }

  const orgusersURL = formatURL(API_URL.ORG_USER.GET_ORG_USER_PK(), {
    has_access: true,
    with_invitations: true,
    tenant: tenantId
  })

  const { data: orgusersList, isLoading: isLoadingOrgusers } =
    useFetchData(orgusersURL)

  const translateRole = useCallback(
    role => {
      const rolesList = t('users_roles_list', { returnObjects: true })
      return rolesList.find(r => r.value === role)?.display || '---'
    },
    [t]
  )

  const translateStatus = useCallback(
    status => {
      const statusesList = t('users_statuses_list', { returnObjects: true })
      return statusesList.find(r => r.value === status)?.display || '---'
    },
    [t]
  )

  const columns = useMemo(() => {
    return [
      { field: 'first_name', title: t('first_name') },
      { field: 'last_name', title: t('last_name') },
      { field: 'email', title: t('email') },
      { field: 'status', title: t('status') }
    ]
  }, [t])

  const bodyData = useMemo(() => {
    if (orgusersList?.results) {
      return orgusersList?.results.map(orguser => {
        return {
          id: orguser.id,
          first_name: orguser.first_name,
          last_name: orguser.last_name,
          email: orguser.email,
          status: translateStatus(orguser.status)
        }
      })
    }
  }, [orgusersList?.results, translateRole, translateStatus])

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => router.push(`${routes.tenant.preview}/${tenantId}`)}
          >
            {t('project_leases_lease_details_portal_preview')}
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={activateChecked}
                disabled={true}
                onChange={handleActivateChange}
                color="primary"
              />
            }
            label={activateChecked ? 'Déactiver' : 'Activer le portail'}
            sx={{ px: 1 }}
          />
        </Grid>
        {activateChecked && (
          <Grid item xs={12} md={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} textAlign={'right'}>
                <Button
                  startIcon={<Add />}
                  onClick={() => setOpenInviteModal(true)}
                >
                  {t('user')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TableTemplate
                  headerProps={{ columns }}
                  bodyProps={{
                    data: bodyData,
                    isLoading: isLoadingOrgusers || !bodyData
                  }}
                  sortProps={{ enabled: true }}
                  placeholderProps={{
                    src: '/Placeholders/13_User-desactivated.png'
                  }}
                  paginationProps={{}}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <CommonModal
        open={openInviteModal}
        title={t('invitation')}
        onClose={() => setOpenInviteModal(false)}
        maxWidth={'sm'}
      >
        <UserForm
          handleClose={() => setOpenInviteModal(false)}
          invitationsListURL={invitationsListURL}
          orgusersURL={orgusersURL}
          orgusers={orgusersList?.results}
          tenantId={tenantId}
        />
      </CommonModal>
    </>
  )
}
