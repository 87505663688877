import React, { useState } from 'react'

import { useFetchData } from '@api/fetchData'

import API_URL from '@config/services'
import HeaderActions from './HeaderActions'
import queryString from '@utils/queryString'
import TasksTable from '@components/Project/Tasks/TasksTable'
import { useAuth } from '@contexts/auth'

import { checkPerms } from '@utils/checkPerms'

const TaskTab = ({
  operationId,
  lotId = null,
  tenantId = null,
  landlordId = null,
  investorId = null,
  fetchDetailModalURL = null
}) => {
  const { organization, organizationTeam, permissions, user } = useAuth()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const defaultQuery = {
    is_logged: false,
    is_archived: false,
    page: 1,
    page_size: rowsPerPage,
    ...(tenantId ? { tenant: tenantId } : {}),
    ...(landlordId ? { landlord: landlordId } : {}),
    ...(investorId ? { investor: investorId } : {}),
    ...(lotId ? { lots: lotId } : {})
  }
  const [queries, setQueries] = useState(defaultQuery)

  const view_members = checkPerms({
    perm: ['view_members'],
    org: organization,
    team: organizationTeam,
    perms: permissions
  })

  const SetFilterQuery = (changedFilter, isDeleted = false) => {
    if (isDeleted) {
      setQueries({
        ...changedFilter,
        page: 1
      })
    } else {
      setQueries(query => ({
        ...query,
        ...changedFilter,
        page: 1
      }))
    }
    setPage(0)
  }

  const fetchListsURL = API_URL.TASKS.GET_TASKS_LISTS_LIST()

  const { data: taskLists } = useFetchData(fetchListsURL)

  const { data: orgusers } = useFetchData(
    view_members
      ? API_URL.ORG_USER.GET_ORG_USER_PK() +
          '?' +
          queryString({ team: organizationTeam, page_size: 999 })
      : null
  )

  const fetchTasksURL =
    API_URL.TASKS.GET_TASK_LIST() + '?' + queryString(queries)

  return (
    <>
      <HeaderActions
        operationId={operationId}
        lotId={lotId}
        tenantId={tenantId}
        landlordId={landlordId}
        investorId={investorId}
        fetchListsURL={fetchListsURL}
        fetchTasksURL={fetchTasksURL}
        queries={queries}
        setQueries={setQueries}
        SetFilterQuery={SetFilterQuery}
        fetchDetailModalURL={fetchDetailModalURL}
      />
      <TasksTable
        queries={queries}
        setQueries={setQueries}
        taskLists={taskLists}
        orgusers={orgusers}
        fetchListsURL={fetchListsURL}
        fetchTasksURL={fetchTasksURL}
        projectId={operationId}
        isPagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        isDetailModal
        lotId={lotId}
        fetchDetailModalURL={fetchDetailModalURL}
      />
    </>
  )
}

export default TaskTab
