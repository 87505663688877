import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Projects from './Projects'
import Invoices from './Invoices'
import Payments from './Payments'

import { useTranslation } from '@contexts/translation'

import {
  Assignment,
  HomeWork,
  Notes,
  Payment,
  InsertDriveFile,
  ConfirmationNumber,
  FormatListBulleted,
  Receipt,
  DoorFront
} from '@mui/icons-material'
import LeasesInfo from './Leases'
import TenantForm from '../../TenantForm'
import TenantInfo from '../../TenantInfo'
import Tickets from './Tickets'
import API_URL from '@config/services'
import Files from '@components/Files/Files'
import TaskTab from '@components/common/DetailModalsTaskTab/TaskTab'
import ButtonTabs from '@components/common/ButtonTabs'
import formatURL from '@utils/formatURL'
import { useAuth } from '@contexts/auth'
import { useRouter } from 'next/router'
import Portal from './Portal'
import routes from '@config/routes'

const TabsComponent = ({
  tenantData,
  handleClose,
  paymentData,
  fetchURL,
  fetchTenantURL,
  tenantId,
  operationId,
  currency
}) => {
  const { user } = useAuth()
  const router = useRouter()
  const { dictionary } = useTranslation()
  const currentURL = router.asPath

  const isTenant =
    user.role === 'tenant' || currentURL.includes('tenant/preview')
  const [value, setValue] = useState('info')

  const commonTabProps = {
    onClick: tabName => setValue(tabName)
  }

  const tenantIconProps = {
    info: { startIcon: Notes },
    projects: { startIcon: HomeWork },
    leases: { startIcon: Receipt },
    invoices: { startIcon: Assignment },
    payments: { startIcon: Payment },
    files: { startIcon: InsertDriveFile },
    tickets: { startIcon: ConfirmationNumber }
  }

  const landlordIconProps = {
    ...tenantIconProps,
    tasks: { startIcon: FormatListBulleted },
    portal: { startIcon: DoorFront }
  }

  const tenantSpecificProps = {
    info: {
      selected: value === 'info',
      text: dictionary.info,
      component: (
        <TenantInfo
          tenantsURL={fetchURL}
          tenantURL={fetchTenantURL}
          tenantData={tenantData}
          handleClose={handleClose}
        />
      )
    },
    projects: {
      selected: value === 'projects',
      text: dictionary.projects,
      component: (
        <Projects projectsData={tenantData.operations} currency={currency} />
      )
    },
    leases: {
      selected: value === 'leases',
      text: dictionary.leases,
      component: (
        <LeasesInfo
          tenantId={tenantData.id}
          operationId={operationId}
          currency={currency}
        />
      )
    },
    invoices: {
      selected: value === 'invoices',
      text: dictionary.invoices,
      component: <Invoices tenantId={tenantData.id} currency={currency} />
    },
    payments: {
      selected: value === 'payments',
      text: dictionary.payments,
      component: <Payments paymentsData={paymentData} currency={currency} />
    },
    files: {
      selected: value === 'files',
      text: dictionary.files,
      component: (
        <Files
          contactId={tenantData.id}
          filesURL={formatURL(API_URL.FILES.GET_FILES(), {
            contacts: tenantData.id
          })}
        />
      )
    },
    tickets: {
      selected: value === 'tickets',
      text: dictionary.tickets,
      component: (
        <Tickets
          operationId={operationId}
          tenantId={tenantId}
          ticketsURL={API_URL.TICKETS.GET_TICKETS()}
        />
      )
    }
  }

  const landlordSpecificProps = {
    ...tenantSpecificProps,
    info: {
      selected: value === 'info',
      text: dictionary.info,
      component: (
        <TenantForm
          tenantsURL={fetchURL}
          tenantURL={fetchTenantURL}
          tenantData={tenantData}
          handleClose={handleClose}
        />
      )
    },
    tasks: {
      selected: value === 'tasks',
      text: dictionary.tasks,
      component: (
        <TaskTab
          operationId={operationId}
          tenantId={tenantData.id}
          fetchDetailModalURL={fetchTenantURL}
        />
      )
    },
    portal: {
      selected: value === 'portal',
      text: dictionary.portal,
      component: (
        <Portal tenantId={tenantId} enabled={tenantData?.portal_enabled} />
      )
    }
  }

  const tabs = isTenant
    ? Object.entries(tenantSpecificProps).map(([tabName, props]) => ({
        ...commonTabProps,
        ...props,
        ...tenantIconProps[tabName],
        onClick: () => commonTabProps.onClick(tabName)
      }))
    : Object.entries(landlordSpecificProps).map(([tabName, props]) => ({
        ...commonTabProps,
        ...props,
        ...landlordIconProps[tabName],
        onClick: () => commonTabProps.onClick(tabName)
      }))

  useEffect(() => {
    if (
      window.history.state.tenantId &&
      window.history.state?.prevUrl ===
        `${routes.tenant.preview}/${window.history.state.tenantId}`
    )
      setValue('portal')
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} px={3}>
        <ButtonTabs tabs={tabs} variant="filled" width="100%" />
      </Grid>

      <Grid item xs={12} padding={3}>
        {tabs.map((tab, i) => (
          <Grid container key={i}>
            {tab.selected && tab.component}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default TabsComponent
