import React, { useEffect, useState } from 'react'

import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack'

import { Button, Grid } from '@mui/material'
import TicketsTable from '@components/Ticketing/TicketsTable'
import TicketForm from '@components/Ticketing/TicketForm'
import CommonModal from '@components/common/Modal/CommonModal'
import { useFetchData } from '@api/fetchData'
import DeleteModal from '@components/common/DeleteModal'
import { onDeleteTicket } from '@api/tickets'
import { responseHandler } from '@utils/responseHandler'
import { mutate } from 'swr'
import GeneralFilter from '@components/common/Filter/GeneralFilter'
import queryString from '@utils/queryString'
import { Add } from '@mui/icons-material'

export default function Tickets(props) {
  const { operationId, tenantId, ticketsURL } = props
  const { dictionary } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [tickets, setTickets] = useState([])
  const [openAddTicket, setOpenAddTicket] = useState({
    open: false,
    id: null
  })
  const [openDeleteTicket, setOpenDeleteTicket] = useState({
    open: false,
    id: null
  })
  const [loading, setLoading] = useState(false)
  const initialQueries = {
    tenant: tenantId
  }

  const [queries, setQueries] = useState(initialQueries)
  const SetFilterQuery = (changedFilter, isDeleted = false) => {
    if (isDeleted) {
      setQueries({
        ...initialQueries,
        ...changedFilter
      })
    } else {
      setQueries(query => ({
        ...query,
        ...changedFilter
      }))
    }
  }

  const fetchURL = ticketsURL + '?' + queryString(queries)
  const { data, isLoading } = useFetchData(fetchURL)

  useEffect(() => {
    if (data) {
      setTickets(data.results)
    }
  }, [data])

  const onConfirmDelete = async () => {
    setLoading(true)
    const response = await onDeleteTicket(openDeleteTicket.id)

    responseHandler({
      response,
      callback: async () => await mutate(fetchURL),
      dictionary,
      msgSuccess: dictionary.ticket_deleted,
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    setOpenDeleteTicket({ open: false, id: null })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 10
            }}
          >
            <GeneralFilter
              queries={queries}
              setQueries={setQueries}
              SetFilterQuery={SetFilterQuery}
              isPagination
              priority
              status
              height="34px"
            />

            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setOpenAddTicket({ open: true })}
              sx={{
                height: 34
              }}
              data-testid="Button-5512564f-5026-47f5-8340-cf0966ab4c6a"
            >
              {dictionary.add_ticket}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TicketsTable
            tickets={tickets}
            isLoading={isLoading}
            handleEdit={ticketId =>
              setOpenAddTicket({ open: true, id: ticketId })
            }
            handleDelete={ticketId =>
              setOpenDeleteTicket({ open: true, id: ticketId })
            }
          />
        </Grid>
      </Grid>

      <CommonModal
        title={
          openAddTicket.id ? dictionary.edit_ticket : dictionary.add_ticket
        }
        open={openAddTicket.open}
        onClose={() => setOpenAddTicket({ open: false, id: null })}
        maxWidth="sm"
      >
        <TicketForm
          operationId={operationId}
          tenantId={tenantId}
          ticketsURL={fetchURL}
          ticketId={openAddTicket.id}
          handleClose={() => setOpenAddTicket({ open: false, id: null })}
        />
      </CommonModal>

      <DeleteModal
        open={openDeleteTicket.open}
        onClose={() => setOpenDeleteTicket({ open: false, id: null })}
        onConfirm={onConfirmDelete}
        description={`${dictionary.confirm_to_delete} ${dictionary.ticket} ?`}
        loading={loading}
      />
    </>
  )
}
