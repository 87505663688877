import React, { useState } from 'react'
import { Button, Stack } from '@mui/material'
import { Add } from '@mui/icons-material'

import { useTranslation } from '@contexts/translation'
import CommonModal from '@components/common/Modal/CommonModal'
import GeneralFilter from '@components/common/Filter/GeneralFilter'
import FilterActionsSelectorChip from '@components/common/Filter/ActionsSelectorChip'
import TaskForm from '@components/Tasks/TaskForm'

export default function HeaderActions(props) {
  const {
    lotId = null,
    tenantId = null,
    landlordId = null,
    investorId = null,
    fetchListsURL,
    fetchTasksURL,
    queries,
    setQueries,
    SetFilterQuery,
    fetchDetailModalURL = null
  } = props
  const { dictionary } = useTranslation()

  const [openForm, setOpenForm] = useState({ open: false, taskId: null })

  return (
    <Stack width="100%">
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
      >
        <Button
          variant="contained"
          startIcon={<Add />}
          data-cy={'add-btn'}
          sx={{ height: 38 }}
          onClick={() => setOpenForm({ open: true, taskId: null })}
          data-testid="Button-dd88809e-6740-4d94-9f5b-d47cca488870"
        >
          {dictionary.add_task}
        </Button>
        {!lotId && (
          <GeneralFilter
            queries={queries}
            setQueries={setQueries}
            SetFilterQuery={SetFilterQuery}
            isPagination
            task_status
            project
          />
        )}

        <FilterActionsSelectorChip
          options={[
            {
              label: dictionary.all,
              handleClick: () => {
                const newQuery = { ...queries }
                delete newQuery.is_completed
                setQueries({ ...newQuery }, true)
              }
            },
            {
              label: dictionary.not_completed,
              handleClick: () => SetFilterQuery({ is_completed: false })
            },
            {
              label: dictionary.completed,
              handleClick: () => SetFilterQuery({ is_completed: true })
            }
          ]}
          height={38}
          borderRadius={3}
        />
      </Stack>

      <CommonModal
        open={openForm.open}
        title={openForm.taskId ? dictionary.edit_task : dictionary.add_task}
        onClose={() => setOpenForm({ open: false, taskId: null })}
        maxWidth={'sm'}
      >
        <TaskForm
          taskId={openForm.taskId}
          lotId={lotId}
          tenantId={tenantId}
          landlordId={landlordId}
          investorId={investorId}
          mutateURLArray={[fetchListsURL, fetchTasksURL]}
          handleClose={() => setOpenForm({ open: false, taskId: null })}
          fetchDetailModalURL={fetchDetailModalURL}
        />
      </CommonModal>
    </Stack>
  )
}
