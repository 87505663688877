import client from '@config/client'

export const onCreateLabel = (orgId, payload = {}) =>
  client
    .post(`/tasks/label/list/${orgId}/`, payload)
    .then(response => response)
    .catch(error => error.response)

export const onEditLabel = (labelId, payload = {}) =>
  client
    .patch(`/tasks/label/${labelId}/`, payload)
    .then(response => response)
    .catch(error => error.response)

export const onDeleteLabel = labelId =>
  client
    .delete(`/tasks/label/${labelId}/`)
    .then(response => response)
    .catch(error => error.response)
