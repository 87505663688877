import React from 'react'

import { useTranslation } from '@contexts/translation'

import { Box, Grid, Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/system'

export default function TenantInfo(props) {
  const { tenantData } = props
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Grid xs={12}>
      <Grid sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderRadius: theme => theme.spacing(3),
                  border: `1px solid ${theme.palette.other.disabled}}`
                }}
              >
                <Stack spacing={3} padding={theme => theme.spacing(3)}>
                  <Typography color="dark.main" sx={{ fontSize: 20, pb: 1 }}>
                    {t('information')}
                  </Typography>
                  <Stack spacing={1}>
                    <Typography
                      color="text.light"
                      textAlign="left"
                      sx={{ fontWeight: 400, fontSize: 13 }}
                    >
                      {t('type')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="dark.main"
                      textAlign="left"
                      sx={{ fontWeight: 500 }}
                    >
                      {tenantData.is_company ? t('company_type') : t('person')}
                    </Typography>
                  </Stack>
                  {!tenantData?.is_company && (
                    <>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('first_name')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.first_name
                            ? tenantData.first_name
                            : '---'}
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('last_name')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.last_name ? tenantData.last_name : '---'}
                        </Typography>
                      </Stack>
                    </>
                  )}
                  {tenantData?.is_company && (
                    <>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('activity_type')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.activity_type
                            ? tenantData.activity_type
                            : '---'}
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('entity_type')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.entity_type
                            ? tenantData.entity_type
                            : '---'}
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('siren')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.siren ? tenantData.siren : '---'}
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('headquarters_address')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.headquarters_address
                            ? tenantData.headquarters_address
                            : '---'}
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('rdc')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.rdc ? tenantData.rdc : '---'}
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('capital')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.capital ? tenantData.capital : '---'}
                        </Typography>
                      </Stack>
                      <Stack spacing={1}>
                        <Typography
                          color="text.light"
                          textAlign="left"
                          sx={{ fontWeight: 400, fontSize: 13 }}
                        >
                          {t('representative')}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="dark.main"
                          textAlign="left"
                          sx={{ fontWeight: 500 }}
                        >
                          {tenantData.representative
                            ? tenantData.representative
                            : '---'}
                        </Typography>
                      </Stack>
                    </>
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderRadius: theme => theme.spacing(3),
                  border: `1px solid ${theme.palette.other.disabled}}`
                }}
              >
                <Stack spacing={3} padding={theme => theme.spacing(3)}>
                  <Typography color="dark.main" sx={{ fontSize: 20, pb: 1 }}>
                    {t('contact')}
                  </Typography>
                  <Stack spacing={1}>
                    <Typography
                      color="text.light"
                      textAlign="left"
                      sx={{ fontWeight: 400, fontSize: 13 }}
                    >
                      {t('email')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="dark.main"
                      textAlign="left"
                      sx={{ fontWeight: 500 }}
                    >
                      {tenantData.email ? tenantData.email : '---'}
                    </Typography>
                  </Stack>
                  <Stack spacing={1}>
                    <Typography
                      color="text.light"
                      textAlign="left"
                      sx={{ fontWeight: 400, fontSize: 13 }}
                    >
                      {t('phone')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="dark.main"
                      textAlign="left"
                      sx={{ fontWeight: 500 }}
                    >
                      {tenantData.phone ? tenantData.phone : '---'}
                    </Typography>
                  </Stack>
                  <Stack spacing={1}>
                    <Typography
                      color="text.light"
                      textAlign="left"
                      sx={{ fontWeight: 400, fontSize: 13 }}
                    >
                      {t('address')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="dark.main"
                      textAlign="left"
                      sx={{ fontWeight: 500 }}
                    >
                      {tenantData.address ? tenantData.address : '---'}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderRadius: theme => theme.spacing(3),
                  border: `1px solid ${theme.palette.other.disabled}}`
                }}
              >
                <Stack spacing={3} padding={theme => theme.spacing(3)}>
                  <Typography color="dark.main" sx={{ fontSize: 20, pb: 1 }}>
                    {t('other')}
                  </Typography>
                  <Stack spacing={1}>
                    <Typography
                      color="text.light"
                      textAlign="left"
                      sx={{ fontWeight: 400, fontSize: 13 }}
                    >
                      {t('website')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="dark.main"
                      textAlign="left"
                      sx={{ fontWeight: 500 }}
                    >
                      {tenantData.website ? tenantData.website : '---'}
                    </Typography>
                  </Stack>
                  <Stack spacing={1}>
                    <Typography
                      color="text.light"
                      textAlign="left"
                      sx={{ fontWeight: 400, fontSize: 13 }}
                    >
                      {t('note')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="dark.main"
                      textAlign="left"
                      sx={{ fontWeight: 500 }}
                    >
                      {tenantData.note ? tenantData.note : '---'}
                    </Typography>
                  </Stack>
                  <Stack spacing={1}>
                    <Typography
                      color="text.light"
                      textAlign="left"
                      sx={{ fontWeight: 400, fontSize: 13 }}
                    >
                      {t('account_number')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="dark.main"
                      textAlign="left"
                      sx={{ fontWeight: 500 }}
                    >
                      {tenantData.account_number
                        ? tenantData.account_number
                        : '---'}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
